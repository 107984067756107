<template>
  <div class="property-list-container">
    <Header
      :details="{
        back: false,
        title: 'Infodays',
      }"
    />
    <div class="wrapper-content">
      <header class="header-list flex">
        <div class="flex sub-header-options">
          <div class="search"><input type="text" v-model="search" :placeholder="$t('overall.search')" /></div>
          <router-link :to="{ name: 'create-article' }" class="cta align-right">
            <plusSVG />
            {{ "Create Infoday" }}
          </router-link>
        </div>
      </header>
      <div class="table-list">
        <ul class="labels">
          <li @click="orderListBy('translations.0.title', articles)" class="table-list__line">
            <button>{{ $t("overall.title") }}</button>
          </li>
          <li @click="orderListBy('dates.0.date', articles)" class="table-list__line">
            <button>{{ "Dates" }}</button>
          </li>
          <li @click="orderListBy('address_name', articles)" class="table-list__line">
            <button>{{ "Location Name" }}</button>
          </li>
        </ul>
        <div class="elements">
          <template v-if="!loading">
            <div class="line" v-for="a in articles" :key="a.token">
              <router-link :to="{ name: 'edit-article', params: { token: a.token } }">
                <div class="table-list__line">
                  {{ a.translations.length && a.translations[0].title ? a.translations[0].title : "-" }}
                </div>
                <div class="table-list__line">{{ a.beautifulDates ? a.beautifulDates : "-" }}</div>
                <div class="table-list__line">{{ a.address_name ? a.address_name : "-" }}</div>
              </router-link>
            </div>
          </template>
          <span class="no-element" v-else>{{ "Loading Infodays" }}</span>
          <span class="no-element" v-if="articles.length < 1 && !loading">No Infoday</span>
        </div>
      </div>
      <Pagination v-if="articles.length" :value="articles" @changeCurrentPage="elementsToDisplay = $event" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import plusSVG from "@/assets/img/plus.svg";
import modalBus from "@/bus/modal-bus";

export default Vue.extend({
  components: { plusSVG },
  name: "ArticlesList",
  computed: {},
  watch: {},
  data() {
    return {
      timer: null,
      articles: [],
      currentPagination: 1,
      pagination: 1,
      elementPerPage: 3,
      elementsToDisplay: [],
      search: "",
      loading: true,
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  methods: {},
  created() {
    this.$axios.get("api/blog").then((res) => {
      this.articles = res.data.articles;

      this.articles.forEach((a, i) => {
        a.beautifulDates = "";
        a.dates.forEach((e) => {
          a.beautifulDates += this.parseBeautifulDate(e.date) + " - ";
        });

        a.beautifulDates = a.beautifulDates.substr(0, a.beautifulDates.length - 3);

        a.translations = a.translations.filter((el) => {
          for (let al of this._global.availableLanguages) {
            if (al.value === el.lang) {
              return true;
            }
          }
        });
      });
      this.loading = false;
      console.log(this.articles);
    });
  },
});
</script>

<style lang="scss" scoped></style>
